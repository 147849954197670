const isMobile = () => {
	let width = Math.min(window.innerWidth, screen.width)

	// console.log('isMobile Width', width, !document.body.classList.contains('disable-mobile'))
	return (
		(/Mobi/i.test(window.navigator.userAgent) || width < 600) &&
		!document.body.classList.contains('disable-mobile')
	)
}
export default isMobile
